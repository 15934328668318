<template>
  <generic-card
      @click="$emit('click')"
      @delete="$emit('deleteContact')"
      :allow-delete="contact._isDeletable"
  >

    <template #iconContent>
      <icon v-if="isIndividual"
            icon="user"
            size="2x"/>
      <icon v-else
            icon="building"
            size="2x"/>
    </template>

    <template #bodyContent>
      <h4>{{ contact._display }}</h4>

      <div v-if="isIndividual && contact.email"
           class="text-truncate">
        <a :href="'mailto:'+contact.email">{{ contact.email }}</a>
      </div>
      <div v-else-if="!isIndividual && contact.phone"
           class="text-truncate">
        <a :href="'tel:'+contact.phone">{{ contact.phone }}</a>
      </div>
      <div v-else>&nbsp;</div>
    </template>
  </generic-card>

</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import GenericCard from './Card'

export default {
  components: {
    GenericCard
  },
  props: {
    contact: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const isIndividual = computed(() => {
      return ('firstName' in props.contact)
    })

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed

      // Methods
      isIndividual,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>