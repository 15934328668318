<template>
  <div>

    <search-filter
        :items="contacts"
        :keyTitleForAlphabeticalSort="'_display'"
        :additionalSortOptions="additionalSortOptions"
        :additionalItemViews="[{ icon: 'globe-americas', value: 'map' }]"
        :selectedView="'grid'"

        :tableColumns="tableColumns"
        @columnClick="selectContact($event)"
        :isActionColumnEnabled="true"
        @addItem="addContact"
    >
      <!--      Grid view-->
      <template v-slot:gridView="{item}">
        <card-contact
            :contact="item"
            @click="selectContact(item)"
            @deleteContact="deleteContactAlert(item)"
        />
      </template>


      <!--      List view-->

      <!--      Cell contact-->
      <template #listView_cell_contact="{item}">
        {{ item._display }}
      </template>

      <!--      Cell email-->
      <template #listView_cell_email="{item}">
        <a :href="'mailto:'+item.email">{{ item.email }}</a>
      </template>

      <!--      Cell phone-->
      <template #listView_cell_phone="{item}">
        <a :href="'tel:'+item.phone">{{ item.phone }}</a>
      </template>

      <!--      Actions-->
      <template #listView_cell_actions="{item}">
        <div class="text-nowrap">

          <!--          Edit-->
          <button-edit
              v-b-tooltip.hover.left="capitalize($t('Edit'))"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

          <!--          Delete-->
          <button-delete
              @click.native.stop="deleteContactAlert(item)"
              v-b-tooltip.hover.left="capitalize($t('delete'))"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
              :disabled="!item._isDeletable"
          />

        </div>
      </template>


    </search-filter>


    <!-- modal contact-->
    <modal-contact
        :contact.sync="currentContact"
        :title="contactModalTitle"
        :isOpen.sync="contactModalShow"
        @submitValidatedContact="submitValidatedContactLocal"
    />

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { mixinContacts } from './mixinContacts'

import i18n from '@/libs/i18n'
import useAPI from '@/utils/useAPI'
import ButtonEdit from '@/components/button/Edit'
import ButtonDelete from '@/components/button/Delete'

export default {
  components: {
    ButtonEdit,
    ButtonDelete
  },
  mixins: [mixinContacts],
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const additionalSortOptions = ref([])
    const tableColumns = ref([
      { key: 'contact', label: i18n.t('contact'), sortable: false },
      { key: 'email', label: i18n.t('email'), sortable: true },
      { key: 'phone', label: i18n.t('phone'), sortable: false },
    ])

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { companies, contacts } = useAPI()

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    watch(companies, () => {
      if (contacts.value.length) {
        contacts.value[0]._filters.forEach(filter => {
          if (additionalSortOptions.value.some(el => el.key == filter.key) == false) {
            additionalSortOptions.value.push(filter)
          }
        })
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      additionalSortOptions,
      tableColumns,

      // Computed
      contacts,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>