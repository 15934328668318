import store from '@/store'
import { computed } from '@vue/composition-api'

export const useContacts = () => {

  const submitValidatedContact = (contact) => {
    if (contact._isCompany) {
      let company = JSON.parse(JSON.stringify(contact.companyEntity))
      if ('_vSelectId' in company) {
        company.id = company._vSelectId
      }

      return new Promise((resolve, reject) => {
        if ('id' in company) {
          updateCompany(company)
            .then(response => {
              resolve(response)
            })
            .catch(error => {
              reject(error)
            })
        } else {
          saveNewCompany(company)
            .then(response => {
              resolve(response)
            })
            .catch(error => {
              reject(error)
            })
        }
      })
    } else {
      let user = JSON.parse(JSON.stringify(contact.userEntity))
      if ('_vSelectId' in user) {
        user.id = user._vSelectId
      }

      return new Promise((resolve, reject) => {
        if ('id' in user) {
          updateUser(user)
            .then(response => {
              resolve(response)
            })
            .catch(error => {
              reject(error)
            })
        } else {
          saveNewUser(user)
            .then(response => {
              resolve(response)
            })
            .catch(error => {
              reject(error)
            })
        }
      })
    }
  }

  const saveNewCompany = (company) => {
    return new Promise((resolve, reject) => {
      store.dispatch('company/addCompany', company)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const saveNewUser = (user) => {
    return new Promise((resolve, reject) => {
      store.dispatch('user/addUser', user)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const updateCompany = (company) => {
    return new Promise((resolve, reject) => {
      store.dispatch('company/updateCompany', company)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const updateUser = (user) => {
    return new Promise((resolve, reject) => {
      store.dispatch('user/updateUser', user)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const removeContact = (contact) => {
    if ('firstName' in contact) {
      return new Promise((resolve, reject) => {
        store.dispatch('user/removeUser', contact)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        store.dispatch('company/removeCompany', contact)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    }
  }

  return {
    submitValidatedContact,
    removeContact,
  }
}