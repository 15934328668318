<template>
  <b-modal
      id="1"
      :title="title"
      v-model="isOpenLocal"
      size="lg"
      scrollable
      hide-footer
      @hide="tryCloseModal"
  >
    <validation-observer #default="{handleSubmit}">
      <b-form @submit.prevent="handleSubmit(submitValidatedContactLocal)">
        <form-contact
            :contact="contactLocal"
        />
        <button ref="submitFormRef"
                class="d-none"></button>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'

import FormContact from '@/components/form/Contact'

export default {
  components: {
    ValidationObserver,
    FormContact
  },
  props: {
    contact: {
      type: Object,
      default: () => {}
    },
    title: {
      type: String,
      default: ''
    },
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const isOpenLocal = ref(false)
    const contactLocal = ref(props.contact)
    const submitFormRef = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(isOpenLocal, (val) => {
      emit('update:isOpen', val)
    })
    watch(contactLocal, (val) => {
      emit('update:contact', val)
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const tryCloseModal = (modal) => {
      if (modal.trigger == 'backdrop') {
        // Click outside
        modal.preventDefault()
        submitFormRef.value.click()
      }
    }

    const submitValidatedContactLocal = () => {
      emit('submitValidatedContact')
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      isOpenLocal,
      contactLocal,
      submitFormRef,

      // Computed

      // Methods
      tryCloseModal,
      submitValidatedContactLocal
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {
    contact: function (val) {
      this.contactLocal = val
    },
    isOpen: function (val) {
      this.isOpenLocal = val
    }
  },
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>